.navbar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    width: 100%;
    padding: 30px 30px 0;
    display: flex;
    align-items: center;
    z-index: 9;

    .injectable {
        * {
            transition: all .3s ease-in-out;
        }
    }

    &.inverted {
        z-index: 9;

        .injectable {

            .isologo-w-square,
            .isologo-w-letter,
            .isologo-t-square,
            .isologo-dot {
                fill: var(--color-dark) !important;
            }

            .isologo-t-letter {
                fill: white !important;
            }
        }

        .hamburger-menu {
            .line {
                background: var(--color-dark);
            }
        }
    }
    
    .hamburger-menu {
        padding: 0;
        margin: 0 0 0 auto;
        border: 0;
        background: transparent;
        position: relative;
        width: 20px;
        height: 15px;
        cursor: pointer;
        
        .line {
            transition: background .3s ease-in-out;
            display: block;
            width: 20px;
            height: 1px;
            background: white;
            
            &:first-of-type,
            &:last-of-type {
                position: absolute;
                right: auto;
                left: 0;
            }
            
            &:first-of-type {
                top: 0;
                bottom: auto;
            }
            
            &:last-of-type {
                top: auto;
                bottom: 0;
            }
        }
    }
}

.right-navbar {
    position: fixed;
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    padding: 0 25px 0 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 8;

    &.inverted {
        > a {
            img,
            svg {
                height: auto;

                polygon,
                path,
                rect {
                    stroke: var(--color-dark) !important;
                    fill: rgba(var(--rgb-color-primary), 0) !important;
                    transition: all .5s ease-in-out, stroke-dashoffset 0s linear;
                }
            }

            &.is-active {
                svg {
                    polygon,
                    path,
                    rect {
                        stroke: var(--color-primary) !important;
                        fill: var(--color-primary) !important;
                    }
                }
            }
        }
    }

    > a {
        display: block;
        margin: 25px 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        img,
        svg {
            height: auto;

            polygon,
            path,
            rect {
                fill: rgba(var(--rgb-color-primary), 0) !important;
                transition: all .5s ease-in-out, stroke-dashoffset 0s linear;
            }
        }

        &.is-active {
            svg {
                polygon,
                path,
                rect {
                    stroke: var(--color-primary) !important;
                    fill: var(--color-primary) !important;
                }
            }
        }
    }
}

.mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 0 30px;
    background: rgba(0, 0, 0, .15);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    z-index: 9999;

    &-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
    }

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: auto;
        left: auto;
        background: transparent;
        border: 0;
        padding: 28px 18px;
    }

    a {
        color: white;
        font-weight: 600;
        font-size: 20px;
        font-family: var(--font-montserrat);
        margin: 20px 0;
        display: block;
        text-decoration: none;
        transition: all .3s ease-in-out;
        text-shadow: 0 3px 5px rgba(0, 0, 0, .25);
        text-align: center;
        line-height: 1.4;

        @media screen and (min-width: 520px) {
            font-size: 24px;
        }

        @media screen and (min-width: 701px) {
            font-size: 32px;
        }

        &:hover,
        &:active,
        &:focus {
            opacity: .5;
            text-decoration: underline;
        }
    }
}