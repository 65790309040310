.how {
    background: var(--color-dark);

    &-desktop-adjustment {
        @media screen and (min-width: 1025px) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;

            &-titles {
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }

            &-left,
            &-right,
            &-center {
                width: 33%;
            }

            &-left {
                padding-right: 30px;
            }

            &-right {
                padding-left: 30px;
            }
        }
    }

    &-fadeout-top {
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        pointer-events: none !important;
        width: 100%;
        height: 150px;
        background: linear-gradient(to bottom, white 0, rgba(white, 0) 100%);
        z-index: 1;

        @media screen and (min-width: 701px) {
            max-width: 700px;
            border-top-left-radius: 40px 75px;
            border-top-right-radius: 40px 75px;
        }

        @media screen and (min-width: 1025px) {
            max-width: 1280px;
        }
    }

    &-intro {
        position: relative;
        padding-bottom: 180px;
        z-index: 3;

        .guide-line {
            position: absolute;
            top: 24px;
            right: auto;
            bottom: auto;
            left: 50%;
            width: 2px;
            height: 205px;
            background: var(--color-gray);
            margin-left: -1px;
            transform-origin: 50% 0;
        }
        
        .hexagon-small-thick {
            width: 42px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .green-circle {
            background: var(--color-primary);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            position: absolute;
            top: auto;
            right: auto;
            bottom: -18px;
            left: 50%;
            margin-left: -18px;
            border: 2px solid var(--color-primary);
        }
    }

    &-content {
        margin-top: -100px;

        @media screen and (min-width: 1025px) {
            margin-top: 0;
            background: white;
            padding-top: 90px;
            position: relative;

            &::before {
                content: '';
                width: 90px;
                height: 90px;
                background: white;
                border-radius: 50%;
                top: 0;
                right: auto;
                bottom: auto;
                left: 50%;
                margin-top: -45px;
                margin-left: -45px;
                position: absolute;
                display: block;
            }
        }

        .white-arch {
            width: 100%;
            height: auto;

            @media screen and (min-width: 701px) {
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }

            @media screen and (min-width: 1025px) {
                display: none;
            }
        }
    }

    &-text {
        margin-top: -1px;
        background: white;
        padding: 0 75px 60px 45px;
        position: relative;

        @media screen and (min-width: 701px) {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            margin-top: -100px;
            border-top-left-radius: 100px;
            border-top-right-radius: 100px;
        }

        @media screen and (min-width: 1025px) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-top: 0;
            max-width: 1280px;
            padding-bottom: 70px;
        }

        h2 {
            font-family: var(--font-montserrat);
            font-weight: 700;
            color: var(--color-dark);
            font-size: 30px;
            letter-spacing: -.5px;
            line-height: 1.4;
            padding-bottom: 14px;
            position: relative;
            display: block;
            margin-bottom: 10px;

            @media screen and (max-width: 400px) {
                font-size: 28px;
            }

            @media screen and (min-width: 1025px) {
                font-size: 42px;
                line-height: 1.2;
            }

            .line {
                display: block;
                position: absolute;
                top: auto;
                right: auto;
                bottom: 7px;
                left: 0;
                width: 100%;
                height: 2px;
                transform-origin: 0 50%;
                background: var(--color-primary);
            }
            
            &.title-2,
            &.title-3,
            &.title-4 {
                margin-top: 55px;
                font-size: 20px !important;
                letter-spacing: 2px;
            }
        }

        h3 {
            color: var(--color-dark);
            font-family: var(--font-montserrat);
            font-size: 26px;
            font-weight: 600;
            line-height: 1.2;
            margin-top: 45px;
            margin-bottom: 60px;

            @media screen and (max-width: 400px) {
                font-size: 22px;
            }
        }
        
        p {
            color: var(--color-dark);
            font-family: var(--font-montserrat);
            font-size: 16px;
            font-weight: 400;
            line-height: 1.4;
            margin-bottom: 15px;

            [data-gif],
            [data-video] {
                text-decoration: underline;
            }

            strong {
                font-weight: 600;
            }
        }

        ul {
            width: 100%;
            background: white;
            border-radius: 32px;
            padding: 30px;
            text-align: left;
            list-style-type: none;
            margin: 0 0 30px;
            box-shadow: 0 5px 15px rgba(black, .1);

            @media screen and (min-width: 701px) {
                margin-left: 0;
                margin-right: 10px;

                &:nth-of-type(2n) {
                    margin-left: 10px;
                    margin-right: 0;
                }
            }

            @media screen and (min-width: 1025px) {
                margin-top: 30px !important;
                vertical-align: top;
                min-height: 242px;
            }

            &:first-of-type,
            &:nth-of-type(3) {
                margin-top: 30px;
            }

            li {
                color: var(--color-dark);
                font-family: var(--font-montserrat);
                font-size: 17px;
                font-weight: 400;
                line-height: 1;
                margin-bottom: 12px;
                text-transform: none;
                letter-spacing: .5px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                h4 {
                    text-transform: none;
                    font-family: var(--font-montserrat);
                    font-weight: 600;
                    color: var(--color-dark);
                    font-size: 20px;
                    letter-spacing: 2px;
                    line-height: 1.4;
                    padding-bottom: 14px;
                    position: relative;
                    display: block;
                    margin-bottom: 5px;
    
                    .line {
                        display: block;
                        position: absolute;
                        top: auto;
                        right: auto;
                        bottom: 7px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        transform-origin: 0 50%;
                        background: var(--color-primary);
                    }
                }

                strong {
                    font-weight: 600;
                }
            }
        }
    }
}