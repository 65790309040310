.intro-hero-section-swipe {
    width: 100%;
    height: 100vh;
    overflow: visible;
    z-index: 2;
}

.intro {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 999;
    background: rgba(#464646, .1);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    transition: -webkit-backdrop-filter 1s ease-in-out;
    transition: backdrop-filter 1s ease-in-out;
    pointer-events: none !important;

    &.is-disabled {
        backdrop-filter: blur(0);
    }

    @media screen and (min-width: 701px) {
        transform: rotate(180deg);
    }
}

.guideline {
    width: 50px;
    position: absolute;
    top: 25%;
    right: auto;
    left: 50%;
    bottom: auto;
    margin-left: -25px;
    height: 50%;
    pointer-events: all;
}

.start-circle {
    border: 2px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
}

.end-circle {
    border: 2px solid black;
    background: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    
    #icon-slide-up {
        position: absolute;
        top: calc(100% + 20px);
        right: auto;
        bottom: auto;
        left: 50%;
        width: 16px;
        height: auto;
        margin-left: -8px;
        animation: float 3s ease-in-out infinite;
    }
    
    .label {
        font-family: var(--font-montserrat);
        color: white;
        font-weight: 600;
        font-size: 11px;
        letter-spacing: 3px;
        text-transform: uppercase;
        position: absolute;
        top: calc(100% + 50px);
        right: auto;
        bottom: auto;
        left: 50%;
        width: 60px;
        text-align: center;
        margin-left: -30px;
        display: block;
        padding-left: 2px;

        @media screen and (min-width: 701px) {
            display: none;
        }

        &.label-mobile {
            display: none !important;
            width: 135px;
            text-align: center;
            display: block;
            margin-left: -70px;      

            @media screen and (min-width: 701px) {
                display: block !important;
                transform: rotate(180deg);
            }
        }
    }
}

.connecting-line {
    width: 2px;
    background: white;
    position: absolute;
    top: 25px;
    right: auto;
    bottom: 25px;
    left: 50%;
    margin-left: -1px;
    pointer-events: none !important;
    z-index: 9999;
}