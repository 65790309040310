canvas {
    display: block;
}

#particle_canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark);
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark);
    background-image: url('');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.description-intro-wrapper {
    background: black url(./../img/hero-bg.png) no-repeat center center / cover;
    background-image: none;
    
    /* .bottom-gradient {
        margin-top: 30px;
        background: linear-gradient(to bottom, rgba(white, 0) 0%, var(--color-gray) 100%);
        height: 120px;
        width: 100%;
    } */
}

.description-intro {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
}

.description-intro h1 {
    margin: 0;
    font-weight: 500;
    font-size: 36px;
    color: white;
    line-height: 1.2;
    position: absolute;
    top: auto;
    right: auto;
    bottom: calc(50% + 30px);
    left: 0;
    padding: 0 30px;

    @media screen and (min-width: 701px) {
        width: 100%;
        max-width: 770px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        font-weight: 600;
        font-size: 42px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 48px;
    }
}

.description-intro h2 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    color: white;
    line-height: 1.2;
    position: absolute;
    top: calc(50% + 80px);
    right: auto;
    bottom: auto;
    left: 0;
    padding: 0 30px;
    strong {
        color: var(--color-primary);
        font-weight: 600;
    }

    @media screen and (min-width: 701px) {
        width: 100%;
        max-width: 770px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }

    @media screen and (min-width: 1025px) {
        font-size: 24px;
    }
}

#semicircle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    width: 100%;
    height: auto;

    @media screen and (min-width: 701px) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        transform: translateY(-100%);
    }
}

#semicircle-bottom {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    transform: rotate(180deg);

    @media screen and (min-width: 701px) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        bottom: 50%;
        transform: translateY(100%) rotate(180deg);
    }
}

#triangle {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 50%;
}

#quarter-circle {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    width: 120px;
    height: auto;
}

#hexagon-orbit {
    position: absolute;
    top: 14%;
    right: 20%;
    bottom: auto;
    left: auto;
    width: 130px;
    height: auto;
}

#circle-small {
    position: absolute;
    top: 55%;
    right: 15%;
    bottom: auto;
    left: auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid rgba(white, .3);
}

#shape-set {
    position: absolute;
    top: calc(50% + 30px);
    right: auto;
    bottom: auto;
    left: 30px;
    width: 112px;
    height: auto;

    @media screen and (min-width: 701px) {
        display: none;
    }
}

#shape-set-mobile {
    position: absolute;
    top: calc(50% + 30px);
    right: auto;
    bottom: auto;
    left: 30px;
    width: 112px;
    height: auto;
    display: none;

    @media screen and (min-width: 701px) {
        width: 645px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        display: block;
        margin-left: 30px;
    }
}

#circle-big {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid rgba(white, .3);
    text-decoration: none;
    display: inline-block;
}

#circle-mask {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid rgba(white, .3);
    background: white;
    text-decoration: none;
    display: inline-block;
    position: absolute;
    top: auto;
    right: 23px;
    bottom: calc(50% + 60px);
    left: auto;

    .guide-line {
        position: absolute;
        width: 2px;
        height: calc(50vh + 220px);
        background: linear-gradient(to bottom, white 0%, white 75%, rgba(white, 0) 100%);
        top: 50%;
        right: 50%;
        bottom: auto;
        left: auto;
        margin-right: -1px;
        transform-origin: 50% 0;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

.scroll-down-indicator {
    width: 180px;
    margin: 0 auto 70px;
    position: absolute;
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-left: -90px;

    @media screen and (min-width: 701px) {
        margin-bottom: 70px;
    }

    .label {
        display: block;
        text-align: center;
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        opacity: .8;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }

    .injectable {
        width: 18px;
        height: auto;
        display: block;
        margin: 5px auto;
        animation: light-float 2s ease-in-out infinite;

        &:nth-of-type(1) {
            animation-delay: .25s;
        }

        &:nth-of-type(2) {
            animation-delay: .5s;
        }

        &:nth-of-type(3) {
            animation-delay: .75s;
        }
    }
}