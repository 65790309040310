.footer {
    text-align: center;
    position: relative;
    background: white;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1025px) {
        max-width: 100%;
    }

    &-wrapper {
        position: relative;

        @media screen and (min-width: 701px) {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1025px) {
            max-width: 100%;
        }
    }

    &-inner {
        padding: 60px 0;
        background: transparent url(./../img/lines-footer-alt.svg) no-repeat left bottom / 200px auto;
        position: relative;
        z-index: 2;
    }

    &-content {
        padding-left: 30px;
        padding-right: 30px;

        .isologo-last {
            .svg-logo-footer-w,
            .svg-logo-footer-frame,
            .svg-logo-footer-d,
            .svg-logo-footer-dot,
            .svg-logo-footer-d-inside,
            .svg-logo-footer-wordmark {
                opacity: 0;
            }
        }
    }

    &-social {
        margin-top: 60px;

        a {
            text-decoration: none;
            color: white;
        }

        img {
            margin: 0 5px;
        }
    }
}