.audiences {
    background: var(--color-medium-dark);

    @media screen and (min-width: 701px) {
        background: var(--color-dark);
    }
    
    @keyframes scroll-up-down {
        0% {
            bottom: 80%;
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        100% {
            bottom: 100%;
            opacity: 0;
        }
    }

    &-scroll-indicator {
        position: absolute;
        top: auto;
        right: auto;
        bottom: 80%;
        left: 50%;
        border-left: 2px solid white;
        border-bottom: 2px solid white;
        display: block;
        width: 16px;
        height: 16px;
        transform: rotate(135deg) translateX(72%);
        animation: 1.6s ease infinite scroll-up-down;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-left: 2px solid white;
            border-bottom: 2px solid white;
            display: block;
            width: 16px;
            height: 16px;
        }

        &::before {
            top: 8px;
            left: -10px;
        }
    }

    &-intro {
        position: relative;
        padding-bottom: 180px;
        z-index: 1;
        background: var(--color-subtle-dark);
        /* background: linear-gradient(to bottom, var(--color-subtle-dark) 0%, var(--color-subtle-dark) 50%, var(--color-dark) 50%, var(--color-dark) 100%); */
        background: linear-gradient(to bottom right, white 0%, white 50%, var(--color-dark) 50%, var(--color-dark) 100%), linear-gradient(to top right, var(--color-dark) 0%, var(--color-dark) 50%, white 50%, white 100%), white;
        background-size: 50% 75%, 50% 75%, cover;
        background-position: 0 100%, 100% 100%, 0 0;
        background-repeat: no-repeat, no-repeat, no-repeat;

        @media screen and (min-width: 701px) {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1025px) {
            background: var(--color-dark);
            max-width: 100%;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: auto;
                bottom: auto;
                left: 50%;
                background: white;
                display: block;
                width: 90px;
                height: 90px;
                transform: rotate(45deg);
                margin-left: -45px;
                margin-top: -57px;
            }
        }

        .guide-line {
            position: absolute;
            top: -12px;
            right: auto;
            bottom: auto;
            left: 50%;
            width: 2px;
            height: 211px;
            background: var(--color-gray);
            margin-left: -1px;
            transform-origin: 50% 0;
            z-index: 3;

            @media screen and (min-width: 1025px) {
                top: -1px;
            }
        }
        
        .triangle-down {
            width: 42px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            top: auto;
            right: auto;
            bottom: 0;
            left: 50%;
            margin-left: -21px;

            @media screen and (min-width: 701px) {
                bottom: -8px;
            }
        }

        .empty-circle {
            background: transparent;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border: 2px solid var(--color-gray);
            margin-left: auto;
            margin-right: auto;
            position: relative;
            top: -30px;

            @media screen and (min-width: 1025px) {
                top: -18px;
            }
        }
    }

    &-box {
        min-height: 100vh;
        background: var(--color-dark);
        position: relative;

        @media screen and (min-width: 701px) {
            min-height: auto;
        }

        &-fadeout-top {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
            pointer-events: none !important;
            width: 100%;
            height: 150px;
            background: linear-gradient(to bottom, var(--color-dark) 0, rgba(var(--rgb-color-dark), 0) 100%);
            z-index: 1;
        }

        @media screen and (min-width: 701px) {
            max-width: 1024px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1025px) {
            max-width: 1280px;
        }

        &-inner {
            overflow: hidden;
            padding: 0;
            width: 100%;
            text-align: center;
            position: relative;

            @media screen and (min-width: 701px) {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
            }

            &-founder,
            &-startup,
            &-corporation {
                padding-left: 50px;
                padding-right: 50px;
                position: relative;
                padding-top: 15px;
                padding-bottom: 15px;

                @media screen and (min-width: 701px) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }

            &-guideline {
                position: absolute;
                width: 60px;
                height: 100%;
                margin-left: 20px;

                @media screen and (min-width: 701px) {
                    display: none;
                    margin-left: 0;
                    width: 100%;
                    height: 60px;
                }

                .audience-guideline-triangle {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 0 5px 8px;
                    border-color: transparent transparent transparent var(--color-gray);
                    position: absolute;
                    top: 50%;
                    right: auto;
                    bottom: auto;
                    left: 0;
                    margin-left: -5px;
                    margin-top: -5px;

                    @media screen and (min-width: 701px) {
                        display: none;
                        top: 0;
                        left: 50%;
                        border-width: 8px 5px 0 5px;
                        border-color: var(--color-gray) transparent transparent transparent;
                    }
                }
                
                .audience-guideline-line-center,
                .audience-guideline-line-left,
                .audience-guideline-line-right {
                    background: var(--color-gray);
                    position: absolute;

                }
                
                .audience-guideline-line-center {
                    width: 14px;
                    height: 1px;
                    top: 50%;
                    right: auto;
                    bottom: auto;
                    left: 0;
                    transform-origin: 100% 50%;

                    @media screen and (min-width: 701px) {
                        display: none;
                        height: 24px;
                        width: 1px;
                        top: 0;
                        left: 50%;
                        transform: 50% 100%;
                    }
                }
                
                .audience-guideline-line-left {
                    width: 1px;
                    height: 50%;
                    top: 0;
                    right: auto;
                    bottom: auto;
                    left: 14px;
                    transform-origin: 0 0;

                    @media screen and (min-width: 701px) {
                        display: none;
                        left: 0;
                        height: 10px;
                        width: 50%;
                    }
                }
                
                .audience-guideline-line-right {
                    width: 1px;
                    height: 50%;
                    top: 50%;
                    right: auto;
                    bottom: auto;
                    left: 14px;
                    transform-origin: 0 0;

                    @media screen and (min-width: 701px) {
                        display: none;
                        width: 50%;
                        height: 1px;
                        left: 50%;
                    }
                }

                &.guideline-founder {
                    .audience-guideline-line-center {
                        transform-origin: 0 50%;

                        @media screen and (min-width: 701px) {
                            display: none;
                            transform-origin: 50% 0;
                        }
                    }
                }
            }

            .info {
                flex: 1 0 100%;
                transform-origin: 0 50%;
                .video-animation{
                    display: flex;
                }

                @media screen and (min-width: 701px) {
                    flex: 1 1 33.333%;
                    width: 33.333%;
                }
            }

            p {
                @media screen and (min-width: 701px) {
                    padding-right: 25px;
                }

                &.ending {
                    font-size: 14px;
                    text-align: left;
                    margin-top: 12px;

                }
            }

        }
    }

    &-content {
        background: var(--color-dark);
        min-height: 100vh;
        padding-bottom: 20px;

        @media screen and (min-width: 701px) {
            max-width: 1024px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 0;
            min-height: auto;
        }

        @media screen and (min-width: 1025px) {
            max-width: 1280px;
        }

        h1 {
            font-family: var(--font-montserrat);
            font-weight: 500;
            font-size: 36px;
            line-height: 1.16;
            color: var(--color-gray);
            padding: 100px 0 30px;
            margin: 0;
            position: relative;
        }

        h2 {
            font-family: var(--font-montserrat);
            font-weight: 700;
            font-size: 20px;
            color: var(--color-primary);
            text-transform: uppercase;
            letter-spacing: 1.5px;
            margin: 2px 0 0 0;
            display: inline-block;

            @media screen and (max-width: 400px) {
                letter-spacing: .5px;
                font-size: 18px;
            }

            @media screen and (min-width: 401px) and (max-width: 768px) {
                font-size: 18px;
            }
        }

        h3 {
            font-size: 27px;
            font-weight: 700;
            text-align: left;
            letter-spacing: 1px;
            color: white;
            font-family: var(--font-montserrat);
            line-height: 1.4;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: auto;
            display: block;
            min-height: 80px;
            strong{
                text-transform: uppercase;
                color: var(--color-primary);
            }
            &::before{
                content: '';
                width: 20px;
                height: 20px;
                display: block;
                background-size: contain;
                background-image: url(./../img/menu-icon-triangle.svg);
                background-repeat: no-repeat;
                background-position: center;
            }

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }



        p {
            font-family: var(--font-montserrat);
            font-weight: 400;
            font-size: 17px;
            color: white;
            margin: 0;
            padding-top: 20px;
            padding-bottom: 25px;
            line-height: 1.5;
            text-align: left;

            @media screen and (max-width: 400px) {
                padding-bottom: 0px;
                padding-top: 0px;
            }

            &:not(.text-1) {
                @media screen and (min-width: 701px) {
                    max-width: 420px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .info {
            margin-top: 130px;
            p:not(.ending) {
                @media screen and (min-width: 1025px) {
                    min-height: 120px;
                }
            }
            img,
            video {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                margin-top: 30px;

                @media screen and (max-width: 400px) {
                    width: 100%;
                    margin-bottom: 0px;
                    margin-top: 0px;
                }

                @media screen and (min-width: 701px) {
                    height: auto;
                    min-width: 300px;
                }
            }

            @media screen and (max-width: 768px) {
                margin-top: 100px;
            }
        }
        .label {
            color: var(--color-primary);
        }

        a {
            font-family: var(--font-montserrat);
            font-weight: 400;
            font-size: 17px;
            color: var(--color-primary);
            letter-spacing: 1px;
            line-height: 1.5;
            display: inline;
            position: relative;

            &:hover {
                background: var(--color-primary);
                color: white;
                transform: translate3d(0, 2px, 0);
                filter: brightness(110%);

                > .label {
                    color: white;
                }
            }
        }
    }

    &-text {
        padding-left: 50px;
        padding-right: 50px;

        @media screen and (min-width: 701px) {
            background: var(--color-dark);
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
        }

        @media screen and (min-width: 1025px) {
            max-width: 1280px;
        }

        h2 {
            font-family: var(--font-montserrat);
            font-weight: 700;
            color: white;
            width: 100%;
            font-size: 29px;
            letter-spacing: -.5px;
            line-height: 1.4;
            padding-bottom: 14px;
            position: relative;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 100px;
            text-transform: initial;

            @media screen and (min-width: 1025px) {
                font-size: 42px;
            }

            .line {
                display: block;
                position: absolute;
                top: auto;
                right: auto;
                bottom: 7px;
                left: 0;
                width: 100%;
                height: 2px;
                transform-origin: 0 50%;
                background: var(--color-primary);
            }
        }
        
        p {
            color: white;
            font-family: var(--font-montserrat);
            font-size: 18px;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 15px;
            padding-right: 60px;

            [data-gif],
            [data-video] {
                text-decoration: underline;
            }

            strong {
                font-weight: 600;
            }
        }
    }
}