@import "reset.scss";
@import "variables.scss";
@import "colors.scss";
@import "fonts.scss";
@import "icons.scss";
@import "mixins.scss";
@import "animation.scss";
@import "form.scss";
@import "components/navbar.scss";
@import "components/intro.scss";
@import "components/hero.scss";
@import "components/about.scss";
@import "components/approach.scss";
@import "components/how.scss";
@import "components/audiences.scss";
@import "components/contact.scss";
@import "components/footer.scss";