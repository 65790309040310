.about-block {
    .about-desktop-adjustment {
        @media screen and (min-width: 1025px) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;

            &-left,
            &-right {
                flex: 1 1 50%;
                width: 50%;
            }

            &-left {
                align-self: center;
            }
        }
    }

    &-content {
        min-height: 100vh;
        position: relative;
        z-index: 1;
        padding-top: 140px;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        background: var(--color-dark);
        padding-top: 90px;

        @media screen and (min-width: 1025px) {
            max-width: 1280px;
            padding-top: 0;
            min-height: auto;
        }

        .paragraph-1,
        .paragraph-2,
        .paragraph-3,
        .paragraph-4, 
        .paragraph-5,
        .paragraph-6 {
            padding-left: 45px;
            padding-right: 45px;
            font-size: 20px;
            margin-bottom: 30px;
            line-height: 1.5;
            font-weight: 400;

            @media screen and (max-width: 400px) {
                font-size: 20px;
            }

            strong {
                font-weight: 600;
                color: var(--color-primary) !important;
            }
        }

        .paragraph-6 a { 
            color: #fff;
            font-weight: 600;
            transition: all .2s;
        }

        .paragraph-6 a:hover {
            opacity: .8;
            transition: all .2s;
        }

        .paragraph-1,
        .paragraph-2,
        .paragraph-3,
        .paragraph-4,
        .paragraph-5,
        .paragraph-6 {
            position: relative;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                background: var(--color-primary);
                border-radius: 50%;
                position: absolute;
                top: 6px;
                right: auto;
                bottom: auto;
                left: 20px;

                @media screen and (min-width: 701px) {
                    top: 14px;
                }
                
                @media screen and (min-width: 1025px) {
                    left: -12px;
                }
            }
        }

        .paragraph-1,
        .paragraph-2,
        .paragraph-3,
        .paragraph-4,
        .paragraph-5,
        .paragraph-6 {
            z-index: 3;
            position: relative;
            color: white;

            @media screen and (min-width: 701px) {
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
                font-size: 24px;
            }

            @media screen and (min-width: 1025px) {
                font-size: 28px;
                padding-left: 15px;
                padding-right: 0;
            }
        }

        .paragraph-1,
        .paragraph-2,
        .paragraph-3,
        .paragraph-4,
        .paragraph-5,
        .paragraph-6{
            font-size: 21px;
        }

        .about-highlight {
            padding-left: 45px;
            padding-right: 45px;
            font-size: 34px;
            font-weight: 600;
            line-height: 1;
            color: var(--color-primary);

            @media screen and (max-width: 400px) {
                font-size: 20px;
                font-weight: 600;
                line-height: 1.5;
            }
        }

        .paragraph-4.txt { 
            color: #222;
            font-size: 20px;
            margin-top: 30px;
            padding-left: 45px;
        }

        .paragraph-4.txt::before {
            display: none;
        } 

        .paragraph-6 {
            margin-top: 30px;
            margin-bottom: 0;
        }

        .masked-content {
            position: relative;
            z-index: 2;
            -webkit-mask-image: url(./../img/mask-hexagon.svg#mask-hexagon);
                    mask-image: url(./../img/mask-hexagon.svg#mask-hexagon);
            -webkit-mask-size: contain;
                    mask-size: contain;
            -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
            -webkit-mask-position: center;
                    mask-position: center;
            background: white;
            padding-top: 160px;
            padding-bottom: 160px;
            margin-top: 0;

            @media screen and (min-width: 701px) {
                width: 520px;
                margin-left: auto;
                margin-right: auto;
                height: 590px;
                padding-top: 220px;
                padding-bottom: 200px;
            }
        }
    }
}