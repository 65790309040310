@import "imports.scss";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
  --font-montserrat: 'Montserrat', sans-serif;
  --color-primary: #00C4BF;
  --color-error: #c7380d;
  --rgb-color-primary: 0, 196, 191;
  --color-gray: #E3E3E5;
  --color-dark-gray: #CCCCCC;
  --rgb-color-gray: 227, 227, 229;
  --color-dark: #1A1A23;
  --rgb-color-dark: 26, 26, 35;
  --color-medium-dark: #21213A;
  --rgb-color-medium-dark: 33, 33, 58;
  --color-subtle-dark: #48484F;
  --rgb-color-subtle-dark: 72, 72, 79;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.link-unstyled {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: var(--font-montserrat);
  background: var(--color-dark);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5, 
p {
  font-family: var(--font-montserrat);
}

.floating-gif,
.floating-video {
  position: fixed;
  top: -9999px;
  left: -9999px;
  width: 240px;
  height: auto;
  border-radius: 16px;
  z-index: 8;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  background: var(--color-subtle-dark);
}

.scrollmagic-pin-spacer {
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}