.contact {
    background: white;

    @media screen and (min-width: 701px) {
        background: var(--color-dark);
    }

    &-fadeout-top {
        position: absolute;
        top: 0 !important;
        right: 0;
        bottom: auto;
        left: 0;
        pointer-events: none !important;
        width: 100%;
        height: 150px;
        background: linear-gradient(to bottom, white 0, rgba(white, 0) 100%);
        z-index: 1;

        @media screen and (min-width: 701px) {
            max-width: 700px;
            left: 50%;
            margin-left: -350px;
        }

        @media screen and (min-width: 1025px) {
            max-width: 100%;
            left: 0;
            margin-left: 0;
        }
    }

    &-intro {
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 300px;
        z-index: 1;
        background: white;
        background: linear-gradient(to bottom, var(--color-dark) 0%, var(--color-dark) 50%, white 50%, white 100%);

        @media screen and (min-width: 701px) {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1025px) {
            max-width: 100%;
        }

        #contact-section {
            position: absolute;
            top: 50%;
            width: 100%;
            left: 0;
            pointer-events: none !important;
        }

        .guide-line {
            position: absolute;
            top: 42px;
            right: auto;
            bottom: auto;
            left: 50%;
            width: 2px;
            height: 210px;
            background: var(--color-gray);
            margin-left: -1px;
            transform-origin: 50% 0;
            z-index: 3;
        }
        
        .triangle-down {
            width: 42px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .empty-square {
            background: transparent;
            width: 36px;
            height: 36px;
            border-radius: 0;
            border: 2px solid var(--color-gray);
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            top: auto;
            right: auto;
            bottom: 30px;
            left: 50%;
            margin-left: -18px;
        }
    }

    &-content {
        min-height: 100vh;
        background: white;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: 1025px) {
            max-width: 100%;
        }
    }

    &-text {
        padding-left: 45px;
        padding-right: 30px;

        @media screen and (min-width: 1025px) {
            max-width: 980px;
            margin-left: auto;
            margin-right: auto;
        }

        h2 {
            font-family: var(--font-montserrat);
            font-weight: 600;
            color: var(--color-dark);
            font-size: 30px;
            letter-spacing: -.5px;
            line-height: 1.4;
            padding-bottom: 14px;
            position: relative;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 100px;
            text-transform: initial;

            @media screen and (max-width: 400px) {
                font-size: 24px;
            }

            @media screen and (min-width: 1025px) {
                font-size: 42px;
                line-height: 1.2;
            }

            .line {
                display: block;
                position: absolute;
                top: auto;
                right: auto;
                bottom: 7px;
                left: 0;
                width: 100%;
                height: 2px;
                transform-origin: 0 50%;
                background: var(--color-primary);
            }
        }
        
        p {
            color: var(--color-dark);
            font-family: var(--font-montserrat);
            font-size: 18px;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 15px;
            padding-right: 60px;

            [data-gif],
            [data-video] {
                text-decoration: underline;
            }

            strong {
                font-weight: 600;
            }
        }
    }

    &-form {
        padding-left: 45px;
        padding-right: 75px;
        padding-top: 15px;
        overflow: hidden;

        @media screen and (min-width: 1025px) {
            max-width: 1010px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        .contact-desktop-adjustments-radios-wrapper,
        textarea {
            @media screen and (min-width: 1025px) {
                flex: 1 1 50%;
                flex: 1 1 calc(50% - 30px);
                width: 50%;
                width: calc(50% - 30px);
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        textarea {
            @media screen and (min-width: 1025px) {
                margin-top: 35px;
            }
        }

        select {
            @media screen and (min-width: 1025px) {
                flex: 0 0 100%;
                flex: 0 0 calc(100% - 30px);
                width: calc(100% - 30px);
                width: 100%;
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        textarea {
            color: var(--color-dark);
            display: block;
            width: 100%;
            background: transparent;
            border: 0;
            border-bottom: 2px solid var(--color-dark-gray);
            margin-bottom: 25px;
            height: 40px;
            line-height: 40px;
            padding: 0 0 0;
            font-family: var(--font-montserrat);
            font-size: 16px;
            font-weight: 500;
            outline: 0;

            &::placeholder {
                color: var(--color-dark-gray);
                opacity: 1;
                font-family: var(--font-montserrat);
                font-size: 16px;
                font-weight: 500;
            }

            &:placeholder-shown {
                color: var(--color-dark-gray);
                border-bottom: 2px solid var(--color-dark-gray);

                &::placeholder {
                    color: var(--color-dark-gray);
                    opacity: 1;
                }
            }

            &:focus {
                color: var(--color-dark);
                border-bottom: 2px solid var(--color-dark);
                outline: 0;
                
                &::placeholder {
                    color: var(--color-dark-gray);
                }
            }
        }

        textarea {
            height: 100px;
            line-height: 1.4;
        }

        select {
            color: var(--color-dark);
            display: block;
            width: 100%;
            background: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 2px solid var(--color-dark-gray);
            margin-bottom: 25px;
            height: 40px;
            line-height: 40px;
            padding: 0 0 0;
            font-family: var(--font-montserrat);
            font-size: 16px;
            font-weight: 500;
            outline: 0;

            &:focus {
                color: var(--color-dark);
                border-bottom: 2px solid var(--color-dark);
            }

            option {
                background: var(--color-dark);
                height: 40px;
                line-height: 40px;
                padding: 0 0 0;
                border-bottom-left-radius: 1px solid var(--color-dark-gray);
                font-family: var(--font-montserrat);
                font-size: 16px;
                font-weight: 500;
                outline: 0;
                color: white;
            }
        }
        
        p {
            color: var(--color-dark);
            font-family: var(--font-montserrat);
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 15px;
            margin-top: 35px;
        }

        .radio-wrapper input[type="radio"] {
            display: none;
        }

        .radio-wrapper label {
            color: var(--color-dark-gray);
            font-family: var(--font-montserrat);
            font-size: 16px;
            font-weight: 500;
            padding-left: 30px;
            margin: 0 0 25px;
            display: block;
            position: relative;
            transition: color .15s ease-in-out;
            line-height: 1.3;
            
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid currentColor;
                top: -1px;
                right: auto;
                bottom: auto;
                left: 0;
                transition: border .15s ease-in-out;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: currentColor;
                top: 4px;
                right: auto;
                bottom: auto;
                left: 5px;
                opacity: 0;
                transition: background .15s ease-in-out, opacity .15s ease-in-out;
            }
        }

        .radio-wrapper input[type="radio"]:checked + label {
            color: var(--color-dark);
        }

        .radio-wrapper input[type="radio"]:checked + label {
            &::after {
                opacity: 1;
            }
        }

        input[name="comdemb33s"] {
            pointer-events: none;
            position: absolute;
            visibility: hidden;
            width: 0;
            height: 0;
            clip-path: polygon(0 0, 0 0, 0 0, 0 0);
            display: block;
            opacity: 0;
        }

        button[type="submit"] {
            display: block;
            width: 100%;
            max-width: 360px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            margin: 0 auto 15px;
            border: 0;
            background: var(--color-primary);
            color: white;
            font-family: var(--font-montserrat);
            font-size: 16px;
            font-weight: 600;
            outline: 0;
            text-transform: uppercase;
            text-align: center;
            clear: both;
            cursor: pointer;

            &.is-sent {
                display: none;
            }

            &.is-sending {
                opacity: .5;
                pointer-events: none;
            }
        }

        small {
            display: block;
            color: var(--color-dark);
            font-size: 14px;
            font-weight: 400;
            font-family: var(--font-montserrat);
            margin-bottom: 30px;
            text-align: center;
            line-height: 1.4;
            width: 100%;
            flex: 0 0 100%;

            &.status-message {
                display: none;

                &.is-shown {
                    display: block;
                }

                &.error,
                &.invalid {
                    color: var(--color-error);
                }

                &.success {
                    color: var(--color-primary);
                    font-size: 16px;
                    font-weight: 600;
                    text-shadow: 0 3px 5px rgba(0, 0, 0, .25);
                    text-transform: uppercase;
                }
            }

            @media screen and (min-width: 1025px) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}