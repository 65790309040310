.approach {
    padding: 0 75px 60px 45px;
    background: var(--color-dark);
    position: relative;
    
    @media screen and (min-width: 1025px) {
        margin-top: 30px;
    }

    &-desktop-adjustment {
        @media screen and (min-width: 1025px) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-fadeout-top {
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        pointer-events: none !important;
        width: 100%;
        height: 130px;
        background: linear-gradient(to bottom, var(--color-dark) 0, rgba(var(--rgb-color-dark), 0) 100%);
        z-index: 1;
    }

    .approach-close{
        h2{
            margin-top: 10px;
            margin-bottom: 10px;
            @media screen and (min-width: 701px) {
                margin-top: 20px;
            }
        }
    }

    h2 {
        font-family: var(--font-montserrat);
        font-weight: 600;
        font-size: 20px;
        color: white;
        line-height: 1.4;
        margin-top: 20px;
        margin-bottom: 50px;

        strong {
            color: var(--color-primary);
            font-weight: 600;
        }

        @media screen and (min-width: 701px) {
            font-size: 20px;
            max-width: 700px;
            margin-top: 90px;
            margin-left: auto;
            margin-right: auto;
            padding-right: 60px;
            line-height: 1.8;        
        }
        @media screen and (min-width: 1025px) {
            margin-bottom: 0;
        }

        &.approach-close{
            font-size: 22px;

            @media screen and (min-width: 701px) {
                padding-right: 0;
                font-size: 28px;
                line-height: 1.4;
            }

            @media screen and (min-width: 1025px) {
                margin-top: 60px;
                margin-bottom: 30px;
            }
        }
    }

    h1 {
        font-family: var(--font-montserrat);
        font-weight: 600;
        font-size: 40px;
        color: white;
        line-height: 1.1;
        margin-bottom: 50px;

        @media screen and (min-width: 701px) {
            font-size: 46px;
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            padding-right: 100px;  
            line-height: 1.4;      
        }

        @media screen and (min-width: 1025px) {
            line-height: 1.2;
            margin-top: 0;
        }
    }

    ul {
        @media screen and (min-width: 701px) {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1025px) {
            margin-top: 90px;
        }

        li {
            font-family: var(--font-montserrat);
            font-weight: 400;
            font-size: 17px;
            color: white;
            line-height: 1.4;
            padding-bottom: 35px;
            padding-left: 30px;
            position: relative;

            @media screen and (min-width: 701px) {
                font-size: 20px;
            }

            em {
                text-decoration: underline;
                padding-right: 5px;
            }

            strong {
                color: var(--color-primary);
                font-weight: 600;
                font-size: 20px;
            }

            .approach-bullet {
                width: 10px;
                height: 10px;
                position: absolute;
                top: 6px;
                right: auto;
                bottom: auto;
                left: 5px;
                transform: rotate(90deg);

                @media screen and (min-width: 701px) {
                    top: 10px
                }
                
                circle {
                    stroke-dasharray: 22;
                }
            }

            &.bullet-2,
            &.bullet-3,
            &.bullet-4 {
                .approach-bullet {
                    transform: rotate(245deg);
                }
            }

            .linking-line {
                width: 1px;
                height: calc(100% - 10px);
                position: absolute;
                top: 16px;
                right: auto;
                bottom: auto;
                left: 9px;
                background: white;
                opacity: .5;

                @media screen and (min-width: 701px) {
                    top: 20px
                }
            }
        }
    }
}